import React from "react";

import Layout from "../components/layout"
//import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <p>404</p>
    <h1>404: Página no encontrada</h1>
    <p>Has digitado una página que no existe</p>
  </Layout>
)

export default NotFoundPage
